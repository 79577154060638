import { GetStaticPropsContext } from 'next';

import { getStaticProps as getProps } from '@backend';
import { Page, PageProps } from '@components/Page';

function Home(pageProps: PageProps): JSX.Element {
  return <Page {...pageProps} />;
}

export async function getStaticProps({ preview }: GetStaticPropsContext) {
  const props = await getProps('/', preview);
  return props;
}

export default Home;
